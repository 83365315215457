export const aboutTeam = [
  {
    url: "men1.jpg",
    name: "John",
    pos: "Owner",
  },
  {
    url: "men2.jpg",
    name: "Alex",
    pos: "Owner",
  },
  {
    url: "women1.jpg",
    name: "Jane",
    pos: "Lead Architect",
  },
  {
    url: "men2.jpg",
    name: "Tom",
    pos: "Architect",
  },
];
