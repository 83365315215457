import React from "react";
import "./aboutvalues.scss";

const AboutValues = () => {
  return (
    <div className="about-container">
      <div className="about-banner-container">
        <img src="/assets/about_values.png" alt="" />
      </div>
      <div className="about-desc-container">
        <h2>Our Values</h2>
        <p>
          Lorem ipsum has been the industry's standard dummy text ever since the
          1500s, when an unknown printer took a galley of type and scrambled it
          to make a type specimen book. Lorem ipsum has been the industry's
          standard dummy text ever since the 1500s,
        </p>
      </div>
    </div>
  );
};

export default AboutValues;
