import React, { useRef, useLayoutEffect, useState } from "react";
import Accordion from "../../../components/accordion/Accordion";
import { accordion } from "../../../data/serviceData";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./servicepage.scss";
gsap.registerPlugin(ScrollTrigger);

const Servicepage = () => {
  const itemRef_2 = useRef(null);
  const cursorMediaRefs = useRef([]);

  useLayoutEffect(() => {
    const el_2 = itemRef_2.current;

    // Title
    gsap.fromTo(
      el_2,
      { autoAlpha: 0, y: 150 },
      {
        autoAlpha: 1,
        duration: 1.2,
        y: 0,
        scrollTrigger: {
          start: "40%",
        },
      }
    );
  }, []);

  return (
    <div className="service-wrapper">
      <div className="sw-content">
        {/* Left Content */}
        <div className="sw-left">
          <div className="description" ref={itemRef_2}>
            <h2>
              We offer <span className="mark">professional design</span> and
              architecture services for any project.
            </h2>
          </div>
        </div>
        {/* Right Content */}
        <div className="sw-right">
          <div className="accordion-content">
            {accordion.map((data, index) => (
              <div className="nav-list" key={index}>
                {" "}
                <Accordion data={data} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Servicepage;
