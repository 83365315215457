import React from "react";
import "./aboutclient.scss";

const AboutClient = () => {
  return (
    <div className="client-container">
      <div className="client-top-caption">
        <p>
          Lorem ipsum has been the industry's standard dummy text ever since the
          1500s, when an unknown printer. Lorem ipsum has.
        </p>
      </div>
      <div className="client-gallery">
        {/* Left Image*/}
        <div className="client-gallery-left">
          <div className="cgl-image-wrap">
            <div className="cgl-image-small">
              <img src="/assets/gallery/img3.jpg" alt="" />
            </div>
            <div className="cgl-image-med">
              <img src="/assets/gallery/img1.jpg" alt="" />
            </div>
          </div>
          <h3>
            Let's <span id="gold">design</span> your living space together.
          </h3>
        </div>
        {/*Right Image */}
        <div className="client-gallery-right">
          <div className="cgr-image-med">
            <img src="/assets/gallery/img5.jpg" alt="" />
          </div>
          <div className="cgr-image-small">
            <img src="/assets/gallery/img6.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutClient;
