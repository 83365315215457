import React, { useRef, useLayoutEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./accordion.scss";
gsap.registerPlugin(ScrollTrigger);

const Accordion = ({ data }) => {
  return (
    <div className="accordion-wrapper">
      {/* Title & icon */}
      <div className="aw-title">
        <div className="aw-t-title">
          <h2>0{data.id}.</h2>
          <h1>{data.title}</h1>
        </div>
      </div>
    </div>
  );
};

export default Accordion;
