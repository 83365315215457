import { useRef } from "react";
import Button from "../../../components/button/Button";
import "./aboutpage.scss";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { motion, useInView } from "framer-motion";
gsap.registerPlugin(ScrollTrigger);

const Aboutpage = () => {
  const topDesc = useRef(null);
  const isInView = useInView(topDesc, { once: true });
  const phrase =
    "Innovating interior spaces with a focus on style and sustainability.";

  return (
    <div className="about-page-wrapper">
      <div className="content-wrapper">
        <div className="top-caption" ref={topDesc}>
          <h2>
            {phrase.split(" ").map((word, index) => {
              if (index == 1 || index == 2)
                return (
                  <span className="mask" id="gold" key={index}>
                    {" "}
                    <motion.span
                      style={{
                        transform: isInView ? "none" : "translateY(200px)",
                        opacity: isInView ? 1 : 0,
                        transition:
                          "all 0.6s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                      }}
                    >
                      {word}
                    </motion.span>
                  </span>
                );
              return (
                <span className="mask" key={index}>
                  {" "}
                  <motion.span
                    style={{
                      transform: isInView ? "none" : "translateY(200px)",
                      opacity: isInView ? 1 : 0,
                      transition:
                        "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                    }}
                  >
                    {word}
                  </motion.span>
                </span>
              );
            })}
          </h2>
        </div>
        <div className="line">
          <svg>
            <line x1={`0%`} y1={40} x2={`100%`} y2={40}></line>
          </svg>
        </div>
        <div className="bottom-caption">
          <div className="bottom-button">
            {" "}
            <a href="/about">
              {" "}
              <Button name="More About us" />
            </a>
          </div>
          <div className="caption-small">
            {" "}
            <p>
              Lorem ipsum has been the industry's standard dummy text ever since
              the 1500s, when an unknown printer took a galley of type and
              scrambled it to make a type specimen book.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aboutpage;
