import { useState, useEffect, useRef } from "react";
import "./slider.scss";
import { motion } from "framer-motion";
import { PiArrowUpRightThin } from "react-icons/pi";
import { Link } from "react-router-dom";

const Slider = ({ images }) => {
  const [width, setWidth] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const carousel = useRef();

  useEffect(() => {
    setWidth(carousel.current.scrollWidth - carousel.current.offsetWidth);
    const getwidth = () => {
      setWidth(carousel.current.scrollWidth - carousel.current.offsetWidth);
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", getwidth);
    return () => setWidth(window.removeEventListener("resize", getwidth));
  }, []);

  return (
    <motion.div ref={carousel} className="slider">
      <motion.div
        className="slider-wrapper"
        drag={screenWidth <= 468 ? "false" : "x"}
        dragConstraints={{ right: 0, left: -width }}
        dragElastic={0.1}
        dragMomentum={true}
        whileTap={{ cursor: "grabbing" }}
      >
        {/* Desktop */}{" "}
        {images.slice(0, screenWidth <= 468 ? 3 : 5).map((image, index) => {
          if (index < 5) {
            return (
              <motion.div className="slide">
                <div className="slide-title">
                  <h2>{image.name}</h2>
                </div>
                <motion.img src={`./assets/gallery/${image.url}`} alt="" />

                <motion.div className="img-info">
                  <Link
                    to={`/Portofolio/${image.url_name}`}
                    // to="#"
                    state={image}
                    key={index}
                    className="info-container"
                  >
                    {" "}
                    <div className="info-left">
                      <h4>{image.area}</h4>
                      <h4>{image.location}</h4>
                      <h4>{image.year}</h4>
                    </div>
                    <div className="info-right">
                      <PiArrowUpRightThin className="info-arrow" size="50px" />
                    </div>
                  </Link>
                </motion.div>
              </motion.div>
            );
          }
        })}
      </motion.div>
    </motion.div>
  );
};

export default Slider;
