import React from "react";
import "./homepage.scss";
import Button from "../../../components/button/Button";

const Homepage = () => {
  //Scroll to footer
  const bottom = () => {
    window.scrollTo({
      top: document.body.offsetHeight,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="home-wrapper">
      {/* Title Heading */}
      <div className="hw-heading">
        <h1>
          Let the{" "}
          <span className="mark" id="italic">
            architecture
          </span>{" "}
          tell its captivating story.
        </h1>
      </div>

      {/* Video */}
      <div className="hw-banner">
        <div onClick={bottom} className="hw-b-button">
          <Button name="Contact us" />
        </div>
        <div className="hw-b-image">
          <video autoPlay muted loop>
            <source src="../assets/video_2.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  );
};

export default Homepage;
