import React from "react";
import "./about.scss";
import AboutTitle from "./about_title/AboutTitle";
import AboutValues from "./about_values/AboutValues";
import AboutTeam from "./about_team/AboutTeam";
import AboutClient from "./about_client/AboutClient";

const About = () => {
  return (
    <div className="about-wrapper">
      <AboutTitle />
      <AboutValues />
      <AboutTeam />
      <AboutClient />
    </div>
  );
};

export default About;
