import React, { useLayoutEffect, useRef } from "react";
import "./abouttitle.scss";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const AboutTitle = () => {
  // top
  const title = useRef(null);

  // Title top
  useLayoutEffect(() => {
    const ti = title.current;
    gsap.set(ti, { autoAlpha: 0, x: -20 });
    gsap.to(ti, { autoAlpha: 1, x: 0, duration: 1, stagger: 0.1 });
  }, []);

  return (
    <div className="aw-introduction">
      <div className="i-description">
        <div className="title" ref={title}>
          {" "}
          <h1>Bluelake</h1>
          <h2 id="italic">Studio</h2>
        </div>
        <p>
          BlueLake Studio embodies the quintessence of architectural ingenuity,
          where each creation is a symphony of form, function, and finesse. Our
          practice is predicated on a holistic approach that meticulously
          considers every element
        </p>
      </div>
      <div className="i-banner">
        <img src="/assets/banner_1.jpg" alt="" />
      </div>
    </div>
  );
};

export default AboutTitle;
