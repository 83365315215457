import React from "react";
import "./aboutteam.scss";
import { aboutTeam } from "../../../data/aboutData";

const AboutTeam = () => {
  return (
    <div className="team-container">
      <div className="team-description">
        <h2>Meet our Team</h2>
        <p>
          Lorem ipsum has been the industry's standard dummy text ever since the
          1500s, when an unknown printer.
        </p>
      </div>
      <div className="team-image">
        <div className="team-image-grid">
          {aboutTeam.map((item, index) => (
            <div className="team-image-details" key={index}>
              <img src={`/assets/about/${item.url}`} alt="" />
              <div className="team-image-details-name">
                {" "}
                <h3>{item.name}</h3>
                <p>{item.pos}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AboutTeam;
