export const accordion = [
  {
    id: 1,
    title: "Architectural Design",
    content:
      "We contact ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    url: "img10.jpg",
  },
  {
    id: 2,
    title: "Interior Design",
    content:
      "We contact ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    url: "img8.jpg",
  },
  {
    id: 3,
    title: "Lightning Design",
    content:
      "We contact ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    url: "img7.jpg",
  },
  // {
  //   id: 4,
  //   title: "Contractor",
  //   content:
  //     "We contact ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  //   url: "img5.jpg",
  // },
];
